/**
 * Resets the properties of an object to their default values.
 *
 * @param {Object} targetObject - The object whose properties will be reset.
 * @param {Array<string>} excludedProperties - An array of property names to ignore during the reset.
 * @returns {Object} The object with its properties reset.
 */
export function resetProperties(targetObject, excludedProperties = []) {
  const defaultValue = (value) => {
    switch (typeof value) {
      case "string":
        return "";
      case "number":
        return 0;
      case "boolean":
        return false;
      case "object":
        if (Array.isArray(value)) {
          return [];
        } else if (value === null) {
          return null;
        } else {
          return {};
        }
      default:
        return value;
    }
  };

  for (const property of Object.keys(targetObject)) {
    if (!excludedProperties.includes(property)) {
      targetObject[property] = defaultValue(targetObject[property]);
    }
  }
  return targetObject;
}

/**
 * Formats a date and time to a localized string.
 *
 * @param {Date|string|number} value - The date to format. Can be a Date object, a date string, or a timestamp.
 * @returns {string} The formatted date and time string.
 */
export function formatDateTime(value) {
  if (!value) return "";
  const date = new Date(value);
  const options = { day: "2-digit", hour: "2-digit", minute: "2-digit", month: "2-digit", year: "numeric" };
  return date.toLocaleString("pt-BR", options);
}

/**
 * Formats a number or string as Brazilian currency (BRL).
 *
 * @param {number|string} value - The number or string to be formatted as currency.
 * @returns {string} The formatted currency string.
 */
export function formatCurrency(value) {
  let numericValue = parseFloat(value);
  if (isNaN(numericValue)) {
    numericValue = Number(value);
  }
  if (isNaN(numericValue)) {
    numericValue = 0;
  }
  return new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(numericValue);
}

/**
 * Converts a given date to ISO format (YYYY-MM-DD) adjusted to the local timezone.
 *
 * @param {Date} date - The date object to convert.
 * @returns {string} The date in ISO format (YYYY-MM-DD).
 */
export function localDateISO(date) {
  const timezoneOffset = date.getTimezoneOffset();
  const localAdjustedDate = new Date(date.getTime() - timezoneOffset * 60 * 1000);
  return localAdjustedDate.toISOString().split("T")[0];
}

/**
 * Adjusts a given date to the local timezone.
 *
 * @param {Date|string|number} value - The date to adjust. Can be a Date object, a date string, or a timestamp.
 * @returns {Date} A new Date object adjusted to the local timezone.
 */
export function adjustToLocalTimezone(value) {
  const date = new Date(value);
  const timezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + timezoneOffset);
}

/**
 * Downloads a file with the specified data, filename, and MIME type.
 *
 * @param {*} data - The data to be downloaded.
 * @param {string} fileName - The name of the file to be downloaded.
 * @param {string} type - The MIME type of the file (e.g., 'application/json', 'text/plain').
 */
export function downloadFile(data, fileName, type) {
  const fileURL = URL.createObjectURL(new Blob([data], { type }));
  const fileLink = document.createElement("a");
  fileLink.href = fileURL;
  fileLink.setAttribute("download", fileName);
  document.body.appendChild(fileLink);
  fileLink.click();
  document.body.removeChild(fileLink);
  URL.revokeObjectURL(fileURL);
}

export function cloneJSON(json) {
  return JSON.parse(JSON.stringify(json));
}

export function copyToClipboard(value) {
  navigator.clipboard.writeText(value);
  this.copied = true;
  setTimeout(() => {
    this.copied = false;
  }, 2000);
}

export function maskBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function maskCep(str) {
  const pattern = "#####-###";
  let i = 0;

  if (!str) {
    return str;
  }

  return pattern.replace(/#/g, () => {
    const char = str.toString()[i++];
    return char ? char : "";
  });
}

export function maskCnpjCpf(value) {
  const cpfPattern = "###.###.###-##";
  const cnpjPattern = "##.###.###/####-##";
  let i = 0;

  if (!value) {
    return value;
  }

  const pattern = value.length > 11 ? cnpjPattern : cpfPattern;

  return pattern.replace(/#/g, () => {
    const char = value.toString()[i++];
    return char ? char : "";
  });
}

export function maskDate(value) {
  const date = new Date(value);
  if (isNaN(date.getTime())) {
    return value;
  }
  return date.toLocaleDateString("pt-BR", { day: "numeric", month: "numeric", year: "numeric" });
}

export function maskEllipsis(value = "", length = 0) {
  if (value.length > length) {
    return `${value.substring(0, length)}...`;
  }
  return value;
}

export function maskMoney(value) {
  return (
    new Intl.NumberFormat("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(value)) +
    " BRL"
  );
}

export function maskNumber(value) {
  return new Intl.NumberFormat("pt-BR", {}).format(Number(value));
}

export function maskPercentage(value) {
  return (
    new Intl.NumberFormat("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(value)) + " %"
  );
}

export function maskPhone(str) {
  const patterns = [
    "+## (##) #####-####",
    "+## (##) ####-####",
    "(##) #####-####",
    "(##) ####-####",
    "#####-####",
    "####-####",
  ];

  if (!str) {
    return str;
  }

  let i = 0;
  const inputDigits = str.replace(/\D/g, ""); // Remove non-digit characters

  const pattern =
    patterns.find((p) => {
      const numDigits = p.replace(/[^#]/g, "").length;
      return inputDigits.length === numDigits;
    }) || patterns[3]; // Default pattern if none is found

  return pattern.replace(/#/g, () => {
    const char = inputDigits[i++];
    return char ? char : "";
  });
}

export function titleCase(value) {
  if (!value) {
    return value;
  }
  return value
    .toString()
    .toLowerCase()
    .split(" ")
    .map((word) => {
      if (word.length < 3) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export default {
  adjustToLocalTimezone,
  cloneJSON,
  copyToClipboard,
  downloadFile,
  formatCurrency,
  formatDateTime,
  localDateISO,
  maskBytes,
  maskCep,
  maskCnpjCpf,
  maskDate,
  maskEllipsis,
  maskMoney,
  maskNumber,
  maskPercentage,
  maskPhone,
  resetProperties,
  titleCase,
};
